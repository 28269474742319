"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceType = exports.ResourceValueScope = exports.ResourcePropertyNonArrayTypes = exports.ResourcePropertyNestedArrayTypes = exports.ResourcePropertyArrayTypes = exports.ResourcePropertyTypeType = exports.getEnumKeyByValue = exports.ResourcePropertyType = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
var ResourcePropertyType;
(function (ResourcePropertyType) {
    ResourcePropertyType["STRING"] = "string";
    ResourcePropertyType["NUMBER"] = "number";
    ResourcePropertyType["BOOLEAN"] = "boolean";
    ResourcePropertyType["DATETIME64"] = "datetime64";
    ResourcePropertyType["STRING_ARRAY"] = "string[]";
    ResourcePropertyType["OBJECT"] = "object";
    ResourcePropertyType["CURRENCY"] = "currency";
    ResourcePropertyType["RELATION"] = "relation";
    ResourcePropertyType["RELATION_ARRAY"] = "relation[]";
    ResourcePropertyType["STAGE"] = "stage";
    ResourcePropertyType["MEMBER"] = "member";
    ResourcePropertyType["DOMAIN"] = "domain";
    ResourcePropertyType["DOMAIN_ARRAY"] = "domain[]";
    ResourcePropertyType["SELECT"] = "select";
    ResourcePropertyType["MULTI_SELECT"] = "multi_select";
    ResourcePropertyType["EMAIL"] = "email";
    ResourcePropertyType["EMAIL_ARRAY"] = "email[]";
    ResourcePropertyType["PHONE_NUMBER"] = "phone_number";
    ResourcePropertyType["PHONE_NUMBER_ARRAY"] = "phone_number[]";
    ResourcePropertyType["PHONE_NUMBER_SUBTYPE"] = "phone_number_subtype";
    ResourcePropertyType["LOCATION"] = "location";
    ResourcePropertyType["LOCATION_SUBTYPE"] = "location_subtype";
    ResourcePropertyType["LINK_HANDLE"] = "link_handle";
    ResourcePropertyType["THUMBNAIL"] = "thumbnail";
    ResourcePropertyType["FULL_NAME"] = "full_name";
    ResourcePropertyType["FULL_NAME_SUBTYPE"] = "full_name_subtype";
    ResourcePropertyType["DATE"] = "date";
})(ResourcePropertyType || (exports.ResourcePropertyType = ResourcePropertyType = {}));
function getEnumKeyByValue(value) {
    for (const [key, val] of Object.entries(ResourcePropertyType)) {
        if (val === value) {
            return ResourcePropertyType[key];
        }
    }
    return undefined;
}
exports.getEnumKeyByValue = getEnumKeyByValue;
exports.ResourcePropertyTypeType = (0, typebox_1.EnumStrings)(typebox_2.Type.Enum(ResourcePropertyType, {
    description: 'Property type. One of:' +
        'string, ' +
        'number, ' +
        'boolean, ' +
        'datetime64, ' +
        'string[], ' +
        'object, ' +
        'currency, ' +
        'relation, ' +
        'relations[], ' +
        'stage, ' +
        'member ' +
        'domain, ' +
        'domains[], ' +
        'select, ' +
        'multi_select, ' +
        'email, ' +
        'email[] ,' +
        'phone_number ,' +
        'phone_number[], ' +
        'location, ' +
        'link_handle, ' +
        'thumbnail, ' +
        'full_name, ' +
        'date',
}));
exports.ResourcePropertyArrayTypes = [
    ResourcePropertyType.STRING_ARRAY,
    ResourcePropertyType.RELATION,
    ResourcePropertyType.RELATION_ARRAY,
    ResourcePropertyType.PHONE_NUMBER_SUBTYPE,
];
exports.ResourcePropertyNestedArrayTypes = [
    ResourcePropertyType.DOMAIN,
    ResourcePropertyType.DOMAIN_ARRAY,
    ResourcePropertyType.MULTI_SELECT,
    ResourcePropertyType.EMAIL,
    ResourcePropertyType.EMAIL_ARRAY,
    ResourcePropertyType.PHONE_NUMBER,
    ResourcePropertyType.PHONE_NUMBER_ARRAY,
];
exports.ResourcePropertyNonArrayTypes = Object.values(ResourcePropertyType).filter((item) => !exports.ResourcePropertyArrayTypes.includes(item));
var ResourceValueScope;
(function (ResourceValueScope) {
    ResourceValueScope["ROOT"] = "ROOT";
    ResourceValueScope["PROPERTIES"] = "PROPERTIES";
})(ResourceValueScope || (exports.ResourceValueScope = ResourceValueScope = {}));
var ResourceType;
(function (ResourceType) {
    ResourceType["OBJECT"] = "OBJECT";
    ResourceType["EVENT"] = "EVENT";
})(ResourceType || (exports.ResourceType = ResourceType = {}));
