"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateWorkspaceObjectRecordVODef = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
const upsertWorkspaceObjectRecordVO_1 = require("./upsertWorkspaceObjectRecordVO");
const recordPropertyModificationVO_1 = require("./recordPropertyModificationVO");
exports.UpdateWorkspaceObjectRecordVODef = typebox_2.Type.Composite([
    upsertWorkspaceObjectRecordVO_1.UpsertWorkspaceObjectRecordVODef,
    typebox_2.Type.Object({
        propertiesOnce: (0, typebox_1.OptionalNullable)(typebox_1.NestedObjectDef),
        propertiesModification: (0, typebox_1.OptionalNullable)(recordPropertyModificationVO_1.RecordPropertyModificationDef),
    }),
]);
