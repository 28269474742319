"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toWorkspaceObjectResponse = exports.WorkspaceObjectPropertiesSchemaDef = exports.WorkspaceObjectArrayPropertyTypes = exports.WorkspaceObjectStatus = exports.WorkspaceObjectType = void 0;
const types_1 = require("../types");
const typebox_1 = require("@sinclair/typebox");
const createWorkspaceObjectPropertyRequest_1 = require("../request/createWorkspaceObjectPropertyRequest");
var WorkspaceObjectType;
(function (WorkspaceObjectType) {
    WorkspaceObjectType["USER"] = "USER";
    WorkspaceObjectType["ACCOUNT"] = "ACCOUNT";
    WorkspaceObjectType["WORKSPACE"] = "WORKSPACE";
    WorkspaceObjectType["NPS"] = "NPS";
    WorkspaceObjectType["CONVERSATION"] = "CONVERSATION";
    WorkspaceObjectType["DEAL"] = "DEAL";
    WorkspaceObjectType["LEAD"] = "LEAD";
    WorkspaceObjectType["SUBSCRIPTION"] = "SUBSCRIPTION";
    WorkspaceObjectType["INVOICE"] = "INVOICE";
    WorkspaceObjectType["CHARGE"] = "CHARGE";
    WorkspaceObjectType["SESSION"] = "SESSION";
    WorkspaceObjectType["CUSTOM"] = "CUSTOM";
    WorkspaceObjectType["COMPANIES"] = "COMPANIES";
    WorkspaceObjectType["PEOPLE"] = "PEOPLE";
    WorkspaceObjectType["STORE"] = "STORE";
    WorkspaceObjectType["ONLINE_STORE"] = "ONLINE_STORE";
    WorkspaceObjectType["APP"] = "APP";
})(WorkspaceObjectType || (exports.WorkspaceObjectType = WorkspaceObjectType = {}));
var WorkspaceObjectStatus;
(function (WorkspaceObjectStatus) {
    WorkspaceObjectStatus["ACTIVE"] = "ACTIVE";
    WorkspaceObjectStatus["INACTIVE"] = "INACTIVE";
    WorkspaceObjectStatus["VIRTUAL"] = "VIRTUAL";
})(WorkspaceObjectStatus || (exports.WorkspaceObjectStatus = WorkspaceObjectStatus = {}));
exports.WorkspaceObjectArrayPropertyTypes = [types_1.ResourcePropertyType.STRING_ARRAY];
exports.WorkspaceObjectPropertiesSchemaDef = typebox_1.Type.Recursive((Self) => typebox_1.Type.Object({
    name: typebox_1.Type.String({ pattern: '^\\$?[A-Za-z0-9_]+$', description: 'Property slug.' }),
    display_name: typebox_1.Type.Optional(typebox_1.Type.String()),
    type: typebox_1.Type.Enum(types_1.ResourcePropertyType),
    properties: typebox_1.Type.Optional(typebox_1.Type.Array(Self)),
    country_display_type: typebox_1.Type.Optional(typebox_1.Type.Enum(createWorkspaceObjectPropertyRequest_1.CountryDisplayType)),
    link_handle_icon: typebox_1.Type.Optional(typebox_1.Type.Enum(types_1.LinkHandleIconType)),
    link_handle_domain: typebox_1.Type.Optional(typebox_1.Type.String()),
    date_format: typebox_1.Type.Optional(typebox_1.Type.String()),
    // traits
    required: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    show_in_record_creation_flow: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    read_only: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    prevent_unknown_values: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    prevent_reports_and_filtering: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    enriched: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    hidden: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
}), {
    $id: 'WorkspaceObjectPropertiesSchemaDef',
});
const toWorkspaceObjectResponse = (workspaceObject) => {
    return {
        id: workspaceObject.id,
        account_id: workspaceObject.accountId,
        workspace_id: workspaceObject.workspaceId,
        api_slug: workspaceObject.apiSlug,
        singular_noun: workspaceObject.singularNoun,
        plural_noun: workspaceObject.pluralNoun,
        object_type: workspaceObject.objectType,
        properties: workspaceObject.properties,
        status: workspaceObject.status,
        label_properties: workspaceObject.labelProperties,
        icon_property: workspaceObject.iconProperty,
        records_changelog_enabled: workspaceObject.recordsChangelogEnabled,
    };
};
exports.toWorkspaceObjectResponse = toWorkspaceObjectResponse;
