import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys.ts';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace.tsx';

interface ObjectsQueryParams {
  status?: Awaited<Parameters<typeof bigdeltaAPIClient.v1.objectsList>>[0]['status'];
  workspaceId?: string;
}

export const useObjectsQuery = ({ status, workspaceId }: ObjectsQueryParams = {} as ObjectsQueryParams) => {
  const { currentWorkspaceId } = useWorkspace();

  const queryKeys = useQueryKeys();

  return useQuery({
    queryKey: queryKeys.list('object', status),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: workspaceId || currentWorkspaceId || '', status: status }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!currentWorkspaceId,
  });
};
