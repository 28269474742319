import { ErrorResponse } from '@bigdelta/lib-shared';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { AppRoutes } from '../../routes';

export const getQueryClient = (onError?: (error: AxiosError<{ message: string }>) => void) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: (failureCount, error) => {
          const axiosError = error as AxiosError<ErrorResponse>;
          return axiosError.response?.status !== 401 && failureCount < 4;
        },
        staleTime: 1000,
        refetchOnWindowFocus: false,
      },
    },
    mutationCache: new MutationCache({
      onError: (error) => {
        if (error instanceof AxiosError && error.response?.status === 401) {
          window.location.href = AppRoutes.LOGIN;
        }
      },
    }),
    queryCache: new QueryCache({
      onError: (error: unknown, query) => {
        if (error instanceof AxiosError) {
          if (error.response?.status === 401) {
            window.location.href = AppRoutes.LOGIN;
            return;
          }

          if (query.meta?.suppressError && Array.isArray(query.meta?.suppressError) && query.meta?.suppressError.includes(error.response?.status)) {
            return;
          }
          onError?.(error);
        }
      },
    }),
  });
