import LoadingIcon from '../../assets/icons/loading-06.svg?react';
import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = HTMLAttributes<SVGSVGElement>;

export const CenteredLoader: React.FC<Props> = ({ className, ...rest }) => {
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
      <LoadingIcon data-testid="loader-icon" className={twMerge('h-12 w-12 animate-spin', className)} {...rest} />
    </div>
  );
};
