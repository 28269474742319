"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateObjectRelationshipVODef = exports.RelationshipNameLowerCaseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("../types");
exports.RelationshipNameLowerCaseDef = typebox_1.Type.String({ pattern: '^[a-z0-9_-]+$' });
exports.CreateObjectRelationshipVODef = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    workspaceId: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String({ pattern: '^[A-Za-z0-9_]+$' }),
    firstEntityWorkspaceId: typebox_1.Type.String({ format: 'uuid' }),
    firstEntityType: typebox_1.Type.Enum(types_1.RelationshipEntityType),
    firstEntityId: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    firstEntityProperty: typebox_1.Type.String({ format: 'uuid' }),
    secondEntityWorkspaceId: typebox_1.Type.String({ format: 'uuid' }),
    secondEntityType: typebox_1.Type.Enum(types_1.RelationshipEntityType),
    secondEntityId: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    secondEntityProperty: typebox_1.Type.String({ format: 'uuid' }),
    relationshipType: typebox_1.Type.Enum(types_1.RelationshipType),
    groupId: typebox_1.Type.String({ format: 'uuid' }),
    dependent: typebox_1.Type.Boolean(),
});
