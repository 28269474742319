"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkHandleIconType = void 0;
var LinkHandleIconType;
(function (LinkHandleIconType) {
    LinkHandleIconType["FACEBOOK"] = "facebook";
    LinkHandleIconType["INSTAGRAM"] = "instagram";
    LinkHandleIconType["X"] = "x";
    LinkHandleIconType["TIKTOK"] = "tiktok";
    LinkHandleIconType["LINKEDIN"] = "linkedin";
    LinkHandleIconType["CRUNCHBASE"] = "cruchbase";
    LinkHandleIconType["PINTEREST"] = "pinterest";
    LinkHandleIconType["YOUTUBE"] = "youtube";
})(LinkHandleIconType || (exports.LinkHandleIconType = LinkHandleIconType = {}));
