"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateType = exports.StringLiterals = exports.EnumStrings = exports.NestedObjectDef = exports.PrimitiveTypeArrayDef = exports.PrimitiveTypeDef = exports.Nullable = exports.OptionalNullable = exports.OptionalNullableRefArray = exports.OptionalNullableRef = void 0;
const typebox_1 = require("@sinclair/typebox");
const OptionalNullableRef = (schema) => typebox_1.Type.Optional(typebox_1.Type.Union([schema, typebox_1.Type.Null()]));
exports.OptionalNullableRef = OptionalNullableRef;
const OptionalNullableRefArray = (schema) => typebox_1.Type.Array((0, exports.OptionalNullableRef)(schema));
exports.OptionalNullableRefArray = OptionalNullableRefArray;
const OptionalNullable = (schema) => typebox_1.Type.Optional((0, exports.Nullable)(schema));
exports.OptionalNullable = OptionalNullable;
const Nullable = (schema) => typebox_1.Type.Union([typebox_1.Type.Null(), schema]);
exports.Nullable = Nullable;
exports.PrimitiveTypeDef = typebox_1.Type.Union([typebox_1.Type.Boolean(), typebox_1.Type.Number(), typebox_1.Type.String(), typebox_1.Type.Null()]);
exports.PrimitiveTypeArrayDef = typebox_1.Type.Union([
    typebox_1.Type.Array(typebox_1.Type.Boolean()),
    typebox_1.Type.Array(typebox_1.Type.Number()),
    typebox_1.Type.Array(typebox_1.Type.String()),
]);
exports.NestedObjectDef = typebox_1.Type.Unsafe({
    type: 'object',
    $ref: 'NestedObjectDef',
});
const EnumStrings = (enumType) => typebox_1.Type.Unsafe(enumType);
exports.EnumStrings = EnumStrings;
const StringLiterals = (literals, options) => typebox_1.Type.Union(literals.map((literal) => typebox_1.Type.Literal(literal)), options);
exports.StringLiterals = StringLiterals;
exports.DateType = typebox_1.Type.Union([typebox_1.Type.String({ format: 'date-time' }), typebox_1.Type.String({ format: 'date' })]);
