"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toInvalidPropertyResponse = exports.InvalidPropertyResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.InvalidPropertyResponseDef = typebox_1.Type.Array(typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    resource_id: typebox_1.Type.String({ format: 'uuid' }),
    property_path: typebox_1.Type.String(),
    error_message: typebox_1.Type.String(),
    hit_count: typebox_1.Type.Number(),
}));
const toInvalidPropertyResponse = (invalidProperties) => {
    return invalidProperties.map((property) => ({
        workspace_id: property.workspaceId,
        resource_id: property.resourceId,
        property_path: property.propertyPath,
        error_message: property.errorMessage,
        hit_count: property.hitCount,
    }));
};
exports.toInvalidPropertyResponse = toInvalidPropertyResponse;
