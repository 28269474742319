import { FC, useMemo } from 'react';
import ColorHash from 'color-hash';
import { Tooltip } from '../Tooltip/Tooltip';
import { DashboardsListData } from '@bigdelta/lib-api-client';
import { twMerge } from '../../../utils/twMerge';

const colorHash = new ColorHash();
type User = DashboardsListData['items'][number]['created_by'];

interface UserTableCellProps {
  user: User | null;
  hideName?: boolean;
}
const SPLIT_CHARS = ['.', '-', '_'];

export const UserTableCell: FC<UserTableCellProps> = ({ user, hideName = false }) => {
  const background = useMemo(() => colorHash.hex(user?.email || ''), [user]);

  if (!user) {
    return null;
  }

  const { first_name, last_name, email = '', photo_image_url } = user;
  const isFullNamePresent = first_name && last_name;

  const Details = () => (
    <div className="flex items-center justify-center gap-2 p-2">
      <div>{first_name && last_name}</div>
      <div>{email}</div>
    </div>
  );

  const extractInitials = (email: string) => {
    const name = email.split('@')[0];
    const splitIndex = SPLIT_CHARS.findIndex((char) => name?.includes(char));

    if (splitIndex >= 0) {
      return name
        ?.split(SPLIT_CHARS[splitIndex]!)
        .map((n) => n.charAt(0).toUpperCase())
        .join('');
    }

    return name?.charAt(0).toUpperCase() || '';
  };

  const initials = isFullNamePresent ? `${first_name?.charAt(0)} ${last_name?.charAt(0)}` : extractInitials(email);
  const CLASSES = 'size-4 rounded-full outline outline-2 hover:outline-m-lime-500';

  return (
    <div className="flex items-center gap-x-4">
      <Tooltip content={<Details />}>
        <div className="w-fit">
          {photo_image_url ? <img src={photo_image_url} alt="" className={CLASSES} /> : null}
          {!photo_image_url && (
            <div className={twMerge(CLASSES, 'flex shrink-0 items-center justify-center  bg-m-red-600 p-4 text-m-white')} style={{ background }}>
              {initials}
            </div>
          )}
        </div>
      </Tooltip>
      {!hideName && <div>{isFullNamePresent ? `${first_name} ${last_name}` : email}</div>}
    </div>
  );
};
