"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCreateWorkspaceObjectPropertyValueResponse = exports.CreateWorkspaceObjectPropertyValueResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const workspaceObjectResponse_1 = require("./workspaceObjectResponse");
exports.CreateWorkspaceObjectPropertyValueResponseDef = typebox_1.Type.Object({
    object: workspaceObjectResponse_1.WorkspaceObjectResponseDef,
    values: typebox_1.Type.Array(model_1.WorkspaceObjectPropertyValueDef),
});
const toCreateWorkspaceObjectPropertyValueResponse = (object, values) => {
    return {
        object: (0, model_1.toWorkspaceObjectResponse)(object),
        values,
    };
};
exports.toCreateWorkspaceObjectPropertyValueResponse = toCreateWorkspaceObjectPropertyValueResponse;
