"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceObjectRecordChangeLogDef = exports.WorkspaceObjectRecordChangeRequestDef = exports.WorkspaceObjectRecordChangeLogTypeStringType = exports.WorkspaceObjectRecordChangeLogType = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
const author_1 = require("./author");
const value_object_1 = require("../value-object");
var WorkspaceObjectRecordChangeLogType;
(function (WorkspaceObjectRecordChangeLogType) {
    WorkspaceObjectRecordChangeLogType["CREATE"] = "CREATE";
    WorkspaceObjectRecordChangeLogType["UPDATE"] = "UPDATE";
    WorkspaceObjectRecordChangeLogType["REPLACE"] = "REPLACE";
})(WorkspaceObjectRecordChangeLogType || (exports.WorkspaceObjectRecordChangeLogType = WorkspaceObjectRecordChangeLogType = {}));
exports.WorkspaceObjectRecordChangeLogTypeStringType = (0, typebox_1.EnumStrings)(typebox_2.Type.Enum(WorkspaceObjectRecordChangeLogType));
exports.WorkspaceObjectRecordChangeRequestDef = typebox_2.Type.Object({
    id: typebox_2.Type.String({ format: 'uuid' }),
    remoteId: typebox_2.Type.String(),
    accountId: typebox_2.Type.String({ format: 'uuid' }),
    workspaceId: typebox_2.Type.String({ format: 'uuid' }),
    workspaceObjectId: typebox_2.Type.String({ format: 'uuid' }),
    propertiesOnce: (0, typebox_1.OptionalNullable)(typebox_1.NestedObjectDef),
    properties: (0, typebox_1.OptionalNullable)(typebox_1.NestedObjectDef),
    propertiesModification: (0, typebox_1.OptionalNullable)(value_object_1.RecordPropertyModificationDef),
    activeSince: typebox_2.Type.String({ format: 'date-time' }),
    modifiedBy: author_1.AuthorDef,
});
exports.WorkspaceObjectRecordChangeLogDef = typebox_2.Type.Object({
    changeId: typebox_2.Type.String({ format: 'uuid' }),
    changeType: exports.WorkspaceObjectRecordChangeLogTypeStringType,
    id: typebox_2.Type.String({ format: 'uuid' }),
    remoteId: typebox_2.Type.String(),
    accountId: typebox_2.Type.String({ format: 'uuid' }),
    workspaceId: typebox_2.Type.String({ format: 'uuid' }),
    workspaceObjectId: typebox_2.Type.String({ format: 'uuid' }),
    propertiesOnce: typebox_1.NestedObjectDef,
    properties: typebox_1.NestedObjectDef,
    propertiesModification: (0, typebox_1.OptionalNullable)(value_object_1.RecordPropertyModificationDef),
    activeSince: typebox_2.Type.String({ format: 'date-time' }),
    systemCreatedAt: (0, typebox_1.OptionalNullable)(typebox_2.Type.String({ format: 'date-time' })),
    modifiedBy: (0, typebox_1.OptionalNullable)(author_1.AuthorDef),
});
