interface JwtTokenPayload {
  jti: string;
}

const AUTH_TOKEN_KEY = 'authentication-token';
const USER_KEY = 'bigdelta-user';
export const USER_ID_KEY = 'bigdelta-user-id';

const extractJWTPayload = (token: string): JwtTokenPayload | null => {
  const payloadBase64 = token.split('.')[1];
  const base64 = payloadBase64?.replace(/-/g, '+').replace(/_/g, '/');

  if (base64) {
    return JSON.parse(window.atob(base64));
  }

  return null;
};

export const setAuthenticationToken = (token: string) => {
  const jwtPayload = extractJWTPayload(token);

  if (jwtPayload) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
    localStorage.setItem(USER_KEY, JSON.stringify(jwtPayload));
    localStorage.setItem(USER_ID_KEY, jwtPayload.jti);
  }
};

export const getAuthenticationToken = (): string | null => {
  return localStorage.getItem(AUTH_TOKEN_KEY);
};

export const getAuthenticatedUserId = (): string | null => {
  return localStorage.getItem(USER_ID_KEY);
};

export const cleanupAuthenticationData = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(USER_ID_KEY);
};
