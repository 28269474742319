"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordPropertyModificationDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("../types");
const typebox_2 = require("../typebox");
exports.RecordPropertyModificationDef = typebox_1.Type.Object({
    path: typebox_1.Type.String(),
    modificationType: typebox_1.Type.Enum(types_1.PropertyOperationType),
    value: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.String())),
});
