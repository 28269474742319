"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateWorkspaceObjectPropertyValueRequestDef = exports.WorkspaceObjectPropertyValuesRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const createWorkspaceObjectPropertyRequest_1 = require("./createWorkspaceObjectPropertyRequest");
exports.WorkspaceObjectPropertyValuesRequestDef = typebox_1.Type.Object({
    value: typebox_1.Type.String(),
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    order: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    color: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});
exports.CreateWorkspaceObjectPropertyValueRequestDef = typebox_1.Type.Object({
    property: createWorkspaceObjectPropertyRequest_1.CreateWorkspaceObjectPropertyRequestDef,
    property_values: typebox_1.Type.Array(exports.WorkspaceObjectPropertyValuesRequestDef),
});
