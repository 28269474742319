"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateWorkspaceObjectPropertyRequestDef = exports.CountryDisplayType = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("../types");
var CountryDisplayType;
(function (CountryDisplayType) {
    CountryDisplayType["COUNTRY_NAME"] = "country_name";
    CountryDisplayType["COUNTRY_CODE"] = "country_code";
})(CountryDisplayType || (exports.CountryDisplayType = CountryDisplayType = {}));
exports.CreateWorkspaceObjectPropertyRequestDef = typebox_1.Type.Object({
    name: typebox_1.Type.String({ pattern: '^\\$?[A-Za-z0-9_]+$', description: 'Property slug.' }),
    display_name: typebox_1.Type.String({ description: 'Property display name.', minLength: 1 }),
    type: typebox_1.Type.Enum(types_1.ResourcePropertyType),
    show_in_record_creation_flow: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    prevent_unknown_values: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    country_display_type: typebox_1.Type.Optional(typebox_1.Type.Enum(CountryDisplayType)),
    link_handle_icon: typebox_1.Type.Optional(typebox_1.Type.Enum(types_1.LinkHandleIconType)),
    link_handle_domain: typebox_1.Type.Optional(typebox_1.Type.String()),
    date_format: typebox_1.Type.Optional(typebox_1.Type.String()),
});
