import { createContext, FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useUser } from '../hooks/useUser';
import { getStoredSelectedWorkspaceId, storeSelectedWorkspaceId } from '../../../shared/utils/selectedWorkspaceId.ts';
import { useAccount } from '../hooks/useAccount.tsx';
import { tracking } from '../../../tracking';

export interface WorkspaceContextType {
  currentWorkspaceId: string;
  selectWorkspace: (workspaceId: string) => void;
}

export const WorkspaceContext = createContext<WorkspaceContextType | null>(null);

interface WorkspaceProviderProps extends PropsWithChildren {
  defaultWorkspaceId: string;
  refresh?: boolean;
}

export const WorkspaceProvider: FC<WorkspaceProviderProps> = ({ children, refresh = true, defaultWorkspaceId }) => {
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState<string>(defaultWorkspaceId);
  const { user, workspaces, accounts } = useUser();
  const { currentAccountId, selectAccount } = useAccount();

  const selectWorkspace = useCallback(
    (workspaceId: string) => {
      if (!user || !currentAccountId) {
        return;
      }

      setCurrentWorkspaceId(workspaceId);
      storeSelectedWorkspaceId(workspaceId);
      tracking.identify(user?.id, currentAccountId, workspaceId);
    },
    [currentAccountId, user]
  );

  useEffect(() => {
    if (!refresh) {
      return;
    }
    const workspaceId = getStoredSelectedWorkspaceId();
    const workspace = workspaces?.find((w) => w.id === workspaceId);
    const account = accounts?.find((a) => a.id === workspace?.account_id);

    if (!workspaceId || !workspace || !account) {
      return;
    }

    if (workspace.account_id !== currentAccountId) {
      selectAccount(account.id);
    }

    selectWorkspace(workspaceId);
  }, [refresh, accounts, currentAccountId, selectAccount, selectWorkspace, workspaces]);

  if (!currentWorkspaceId) {
    return null;
  }

  return <WorkspaceContext.Provider value={{ currentWorkspaceId, selectWorkspace }}>{children}</WorkspaceContext.Provider>;
};
