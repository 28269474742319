"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivateRecordPropertyModificationRequestDef = exports.RecordPropertyModificationRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const typebox_2 = require("../typebox");
exports.RecordPropertyModificationRequestDef = typebox_1.Type.Object({
    path: typebox_1.Type.String(),
    value: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.String())),
    active_since: (0, typebox_2.OptionalNullable)(typebox_2.DateType),
});
exports.PrivateRecordPropertyModificationRequestDef = typebox_1.Type.Intersect([
    exports.RecordPropertyModificationRequestDef,
    typebox_1.Type.Object({
        modified_by: model_1.AuthorDef,
    }),
]);
