"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toRelationshipResponse = exports.RelationshipResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("../types");
exports.RelationshipResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    first_entity_workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    first_entity_type: typebox_1.Type.Enum(types_1.RelationshipEntityType),
    first_entity_id: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    first_entity_property: typebox_1.Type.String(),
    second_entity_workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    second_entity_type: typebox_1.Type.Enum(types_1.RelationshipEntityType),
    second_entity_id: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    second_entity_property: typebox_1.Type.String(),
    relationship_type: typebox_1.Type.Enum(types_1.RelationshipType),
    group_id: typebox_1.Type.String({ format: 'uuid' }),
    created_at: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
});
const toRelationshipResponse = (relationship) => {
    return {
        id: relationship.id,
        workspace_id: relationship.workspaceId,
        name: relationship.name,
        first_entity_workspace_id: relationship.firstEntityWorkspaceId,
        first_entity_type: relationship.firstEntityType,
        first_entity_id: relationship.firstEntityId,
        first_entity_property: relationship.firstEntityProperty,
        second_entity_workspace_id: relationship.secondEntityWorkspaceId,
        second_entity_type: relationship.secondEntityType,
        second_entity_id: relationship.secondEntityId,
        second_entity_property: relationship.secondEntityProperty,
        relationship_type: relationship.relationshipType,
        group_id: relationship.groupId,
        created_at: relationship.systemCreatedAt,
        updated_at: relationship.systemUpdatedAt,
    };
};
exports.toRelationshipResponse = toRelationshipResponse;
