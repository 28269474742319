"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceObjectRecordChangesResponseDef = exports.WorkspaceObjectRecordLastPropertiesModificationDef = exports.WorkspaceObjectRecordModificationDetailsDef = exports.WorkspaceObjectRecordChangeDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const response_1 = require("../response");
const types_1 = require("../types");
exports.WorkspaceObjectRecordChangeDef = typebox_1.Type.Object({
    modified_by: response_1.AuthorResponseDef,
    modified_at: typebox_1.Type.String({ format: 'date-time' }),
    type: typebox_1.Type.Enum(types_1.ChangeType),
    properties: typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Object({
        action: typebox_1.Type.Enum(types_1.ActionType),
        old_value: (0, typebox_2.OptionalNullable)(typebox_1.Type.Union([typebox_2.PrimitiveTypeDef, typebox_2.PrimitiveTypeArrayDef, typebox_2.NestedObjectDef])),
        new_value: (0, typebox_2.OptionalNullable)(typebox_1.Type.Union([typebox_2.PrimitiveTypeDef, typebox_2.PrimitiveTypeArrayDef, typebox_2.NestedObjectDef])),
    })),
});
exports.WorkspaceObjectRecordModificationDetailsDef = typebox_1.Type.Object({
    modified_by: response_1.AuthorResponseDef,
    modified_at: typebox_1.Type.String({ format: 'date-time' }),
    value: (0, typebox_2.OptionalNullable)(typebox_1.Type.Union([typebox_2.PrimitiveTypeDef, typebox_2.PrimitiveTypeArrayDef, typebox_2.NestedObjectDef])),
});
exports.WorkspaceObjectRecordLastPropertiesModificationDef = typebox_1.Type.Record(typebox_1.Type.String(), exports.WorkspaceObjectRecordModificationDetailsDef);
exports.WorkspaceObjectRecordChangesResponseDef = typebox_1.Type.Object({
    changes: typebox_1.Type.Array(exports.WorkspaceObjectRecordChangeDef),
    last_modifier_by_property: exports.WorkspaceObjectRecordLastPropertiesModificationDef,
    modified_enriched_properties: typebox_1.Type.Array(typebox_1.Type.String()),
});
