import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from './routes/Layout.tsx';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import UserContextProvider from './features/auth/context/userContext.tsx';
import { ReportsList } from './features/reports/pages/ReportsList.tsx';
import { AppRoutes, DashboardsRoutes, LookupRoutes, ObjectsRoutes, RecordsRoutes, ReportsRoutes } from './routes';
import { toast } from 'react-hot-toast';
import { Toaster } from './components/Toaster.tsx';
import { ToastContent } from './components/ToastContent.tsx';

import AlertIcon from './assets/icons/alert-circle.svg?react';
import { SegmentsPage } from './features/segments/pages/Segments.tsx';
import { lazy, Suspense } from 'react';
import { PageViewTracker } from './tracking';
import { WorkspaceContextRoute } from './features/auth/components/WorkspaceContextRoute.tsx';
import { ProtectedRoute } from './features/auth/components/ProtectedRoute.tsx';
import { AccountContextRoute } from './features/auth/components/AccountContextRoute.tsx';
import { DashboardsList } from './features/dashboard/pages/DashboardsList.tsx';
import { PlanningEmptyPage } from './features/planning/pages/PlanningEmptyPage.tsx';
import { SettingsLayout } from './routes/SettingsLayout.tsx';
import { ErrorBoundary } from '@sentry/react';
import { ErrorFallback } from './shared/components/ErrorFallback';
import { getQueryClient } from './shared/data/queryClient.ts';

const AcceptInvitation = lazy(() => import('./features/invitations/AcceptInvitationPage.tsx'));

const AutomationsList = lazy(() => import('./features/automations/pages/AutomationsListPage.tsx'));
const AutomationsCreate = lazy(() => import('./features/automations/pages/AutomationsCreatePage.tsx'));
const AutomationsViewPage = lazy(() => import('./features/automations/pages/AutomationsViewPage.tsx'));
const BiggiePage = lazy(() => import('./features/biggie/BiggiePage.tsx'));

const Events = lazy(() => import('./features/events/pages/Events.tsx'));

const WorkspaceGeneralSettingsPage = lazy(() => import('./features/settings/workspace/pages/WorkspaceGeneralSettingsPage.tsx'));
const ProfileSettingsPage = lazy(() => import('./features/settings/profile/pages/ProfileSettingsPage.tsx'));
const AccessKeysPage = lazy(() => import('./features/accesskeys/AccessKeysPage.tsx'));
const IntegrationProfilePage = lazy(() => import('./features/integrations/pages/IntegrationProfilePage.tsx'));
const IntegrationsPage = lazy(() => import('./features/integrations/pages/IntegrationsPage.tsx'));
const IntegrationsSlackAuthPage = lazy(() => import('./features/integrations/pages/IntegrationsSlackAuthPage.tsx'));
const MembersList = lazy(() => import('./features/members/MembersList.tsx'));
const ObjectsListPage = lazy(() => import('./features/objects/pages/ObjectsListPage.tsx'));
const ObjectPage = lazy(() => import('./features/objects/pages/ObjectPage.tsx'));
const BillingPage = lazy(() => import('./features/settings/billing/BillingPage.tsx'));

const AppsPage = lazy(() => import('./features/apps/pages/Apps.tsx'));
const RecordsSinglePage = lazy(() => import('./features/records/pages/RecordsSinglePage.tsx'));
const RecordsListPageWrapper = lazy(() => import('./features/records/pages/RecordsListPageWrapper.tsx'));
const LookupPage = lazy(() => import('./features/lookup/pages/Lookup.tsx'));

const RecordsIndex = lazy(() => import('./features/records/pages/RecordsIndex.tsx'));

const LoginPage = lazy(() => import('./features/auth/pages/Login.tsx'));
const ForgotPasswordPage = lazy(() => import('./features/auth/pages/ForgotPassword.tsx'));
const ResetPasswordPage = lazy(() => import('./features/auth/pages/ResetPassword.tsx'));

const ReportPage = lazy(() => import('./features/reports/pages/Report.tsx'));

const WorkspaceOnboarding = lazy(() => import('./features/workspaces/onboarding/pages/WorkspaceOnboarding'));
const MemberOnboardingPage = lazy(() => import('./features/members/onboarding/pages/MemberOnboardingPage.tsx'));
const AccountOnboardingPage = lazy(() => import('./features/account/onboarding/pages/AccountOnboardingPage.tsx'));

const SignUpPage = lazy(() => import('./features/sign-up/pages/SignUpPage.tsx'));

const DashboardCustomerLifecyclePage = lazy(() => import('./features/dashboard/pages/DashboardCustomerLifecyclePage'));
const Dashboard = lazy(() => import('./features/dashboard/pages/Dashboard.tsx'));

const DEVTOOLS_ENABLED_KEY = 'rq-devtools-enabled';

const isQueryDevtoolsEnabled = () => {
  return localStorage.getItem(DEVTOOLS_ENABLED_KEY) !== 'false';
};

const queryClient = getQueryClient((error) => {
  toast(<ToastContent message={error?.response?.data?.message} />, {
    icon: <AlertIcon className="h-5 w-5 text-m-red-600" />,
  });
});

const App = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary fallback={({ resetError, error }) => <ErrorFallback resetErrorBoundary={resetError} error={error} />}>
        <UserContextProvider>
          <QueryClientProvider client={queryClient}>
            <PageViewTracker />
            <Routes>
              <Route
                path={AppRoutes.ACCEPT_INVITATION}
                element={
                  <Suspense>
                    <AcceptInvitation />
                  </Suspense>
                }
              />
              <Route
                path={AppRoutes.LOGIN}
                element={
                  <Suspense>
                    <LoginPage />
                  </Suspense>
                }
              />
              <Route
                path={AppRoutes.FORGOT_PASSWORD}
                element={
                  <Suspense>
                    <ForgotPasswordPage />
                  </Suspense>
                }
              />
              <Route
                path={AppRoutes.RESET_PASSWORD}
                element={
                  <Suspense>
                    <ResetPasswordPage />
                  </Suspense>
                }
              />

              <Route
                path={AppRoutes.SIGN_UP}
                element={
                  <Suspense>
                    <SignUpPage />
                  </Suspense>
                }
              />
              <Route Component={ProtectedRoute}>
                <Route
                  path={AppRoutes.MEMBERS_ONBOARDING}
                  element={
                    <Suspense>
                      <MemberOnboardingPage />
                    </Suspense>
                  }
                />
                <Route
                  path={AppRoutes.ACCOUNT_ONBOARDING}
                  element={
                    <Suspense>
                      <AccountOnboardingPage />
                    </Suspense>
                  }
                />

                <Route Component={AccountContextRoute}>
                  <Route
                    path={AppRoutes.WORKSPACES_ONBOARDING}
                    element={
                      <Suspense>
                        <WorkspaceOnboarding />
                      </Suspense>
                    }
                  />
                  <Route Component={WorkspaceContextRoute}>
                    <Route Component={SettingsLayout}>
                      <Route
                        path={AppRoutes.SETTINGS_WORKSPACE_GENERAL}
                        element={
                          <Suspense>
                            <WorkspaceGeneralSettingsPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.SETTINGS_PROFILE}
                        element={
                          <Suspense>
                            <ProfileSettingsPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.ACCESS_KEYS}
                        element={
                          <Suspense>
                            <AccessKeysPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.VIEW_INTEGRATION}
                        element={
                          <Suspense>
                            <IntegrationProfilePage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.INTEGRATIONS}
                        element={
                          <Suspense>
                            <IntegrationsPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.INTEGRATIONS_SLACK_AUTH}
                        element={
                          <Suspense>
                            <IntegrationsSlackAuthPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.INTEGRATIONS_STRIPE_AUTH}
                        element={
                          <Suspense>
                            <IntegrationsPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.MEMBERS}
                        element={
                          <Suspense>
                            <MembersList />
                          </Suspense>
                        }
                      />
                      <Route
                        path={ObjectsRoutes.SETTINGS_WORKSPACE_LIST}
                        element={
                          <Suspense>
                            <ObjectsListPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={ObjectsRoutes.SETTINGS_WORKSPACE_PREVIEW}
                        element={
                          <Suspense>
                            <ObjectPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={ObjectsRoutes.SETTINGS_WORKSPACE_NEW}
                        element={
                          <Suspense>
                            <ObjectPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.BILLING}
                        element={
                          <Suspense>
                            <BillingPage />
                          </Suspense>
                        }
                      />
                    </Route>

                    <Route Component={Layout}>
                      <Route path={AppRoutes.REPORTS}>
                        <Route index Component={ReportsList} />
                        <Route
                          path={ReportsRoutes.CREATE_REPORT}
                          element={
                            <Suspense>
                              <ReportPage />
                            </Suspense>
                          }
                        />
                        <Route
                          path={ReportsRoutes.VIEW_REPORT}
                          element={
                            <Suspense>
                              <ReportPage />
                            </Suspense>
                          }
                        />
                      </Route>
                      <Route path={AppRoutes.SEGMENTS}>
                        <Route index path="*" Component={SegmentsPage} />
                      </Route>

                      <Route path={AppRoutes.PLANNING} Component={PlanningEmptyPage} />

                      <Route
                        path={AppRoutes.EVENTS}
                        element={
                          <Suspense>
                            <Events />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.APPS}
                        element={
                          <Suspense>
                            <AppsPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={LookupRoutes.INDEX}
                        element={
                          <Suspense>
                            <LookupPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={RecordsRoutes.VIEW_RECORD}
                        element={
                          <Suspense>
                            <RecordsSinglePage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={RecordsRoutes.LIST}
                        element={
                          <Suspense>
                            <RecordsListPageWrapper />
                          </Suspense>
                        }
                      />
                      <Route
                        path={RecordsRoutes.INDEX}
                        element={
                          <Suspense>
                            <RecordsIndex />
                          </Suspense>
                        }
                      />

                      <Route
                        path={AppRoutes.AUTOMATIONS}
                        element={
                          <Suspense>
                            <AutomationsList />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.AUTOMATIONS_CREATE}
                        element={
                          <Suspense>
                            <AutomationsCreate />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.AUTOMATIONS_VIEW(':id')}
                        element={
                          <Suspense>
                            <AutomationsViewPage />
                          </Suspense>
                        }
                      />
                      <Route path={DashboardsRoutes.INDEX} Component={DashboardsList}>
                        {/* <Route path={DashboardsRoutes.CREATE_DASHBOARD} Component={CreateDashboardPage} />
                        <Route path={DashboardsRoutes.EDIT_DASHBOARD} Component={EditDashboardPage} /> */}
                      </Route>
                      <Route
                        path={DashboardsRoutes.VIEW_DASHBOARD_CUSTOMER_LIFECYCLE}
                        element={
                          <Suspense>
                            <DashboardCustomerLifecyclePage />
                          </Suspense>
                        }
                      />
                      <Route
                        path={DashboardsRoutes.VIEW_DASHBOARD}
                        element={
                          <Suspense>
                            <Dashboard />
                          </Suspense>
                        }
                      />
                      <Route
                        path={AppRoutes.BIGGIE}
                        element={
                          <Suspense>
                            <BiggiePage />
                          </Suspense>
                        }
                      />
                      <Route path={AppRoutes.ROOT} Component={DashboardsList} />
                    </Route>
                  </Route>
                </Route>
              </Route>
              {/* <Route path="*" element={<Navigate to={AppRoutes.REPORTS} replace />} /> */}
            </Routes>
            <Toaster />
            {isQueryDevtoolsEnabled() && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
          </QueryClientProvider>
        </UserContextProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
