"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateRelationshipRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("../types");
const typebox_2 = require("../typebox");
exports.CreateRelationshipRequestDef = typebox_1.Type.Object({
    name: typebox_1.Type.String({ pattern: '^[A-Za-z0-9_]+$' }),
    display_name: typebox_1.Type.String(),
    first_entity_type: typebox_1.Type.Enum(types_1.RelationshipEntityType),
    first_entity_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    first_entity_property: typebox_1.Type.String(),
    second_entity_type: typebox_1.Type.Enum(types_1.RelationshipEntityType),
    second_entity_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    second_entity_property: typebox_1.Type.String(),
});
