"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderPropertyName = void 0;
const lodash_1 = require("lodash");
const renderPropertyName = (propertyName) => {
    if (!propertyName) {
        return '';
    }
    return propertyName
        .replace(/\$/g, '')
        .split('.')
        .map((part) => (0, lodash_1.capitalize)(part))
        .join(' -> ')
        .split('_')
        .join(' ');
};
exports.renderPropertyName = renderPropertyName;
