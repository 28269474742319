"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceObjectPropertyValueDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
exports.WorkspaceObjectPropertyValueDef = typebox_1.Type.Composite([
    model_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        workspace_id: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        workspace_object_id: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        property_name: typebox_1.Type.Optional(typebox_1.Type.String()),
        property_value: typebox_1.Type.Optional(typebox_1.Type.String()),
        property_color: typebox_1.Type.Optional(typebox_1.Type.String()),
        order: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    }),
]);
