"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelationshipDef = void 0;
const timestampedModel_1 = require("./timestampedModel");
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("../types");
exports.RelationshipDef = typebox_1.Type.Composite([
    timestampedModel_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        name: typebox_1.Type.String(),
        workspaceId: typebox_1.Type.String({ format: 'uuid' }),
        firstEntityWorkspaceId: typebox_1.Type.String({ format: 'uuid' }),
        firstEntityId: typebox_1.Type.String({ format: 'uuid' }),
        firstEntityType: typebox_1.Type.Enum(types_1.RelationshipEntityType),
        firstEntityProperty: typebox_1.Type.String({ format: 'uuid' }),
        secondEntityWorkspaceId: typebox_1.Type.String({ format: 'uuid' }),
        secondEntityId: typebox_1.Type.String({ format: 'uuid' }),
        secondEntityType: typebox_1.Type.Enum(types_1.RelationshipEntityType),
        secondEntityProperty: typebox_1.Type.String({ format: 'uuid' }),
        relationshipType: typebox_1.Type.Enum(types_1.RelationshipType),
        groupId: typebox_1.Type.String({ format: 'uuid' }),
        dependent: typebox_1.Type.Boolean(),
    }),
]);
