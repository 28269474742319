"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelationshipType = void 0;
var RelationshipType;
(function (RelationshipType) {
    RelationshipType["UNIDIRECTIONAL"] = "UNIDIRECTIONAL";
    RelationshipType["UNIDIRECTIONAL_MULTIPLE"] = "UNIDIRECTIONAL_MULTIPLE";
    RelationshipType["ONE_TO_ONE"] = "ONE_TO_ONE";
    RelationshipType["ONE_TO_MANY"] = "ONE_TO_MANY";
    RelationshipType["MANY_TO_ONE"] = "MANY_TO_ONE";
    RelationshipType["MANY_TO_MANY"] = "MANY_TO_MANY";
})(RelationshipType || (exports.RelationshipType = RelationshipType = {}));
