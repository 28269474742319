"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyOperationType = void 0;
var PropertyOperationType;
(function (PropertyOperationType) {
    PropertyOperationType["UNION"] = "UNION";
    PropertyOperationType["APPEND"] = "APPEND";
    PropertyOperationType["REMOVE"] = "REMOVE";
    PropertyOperationType["DELETE"] = "DELETE";
})(PropertyOperationType || (exports.PropertyOperationType = PropertyOperationType = {}));
