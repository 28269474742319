import { useMemo } from 'react';

import { useUser } from '../../features/auth/hooks/useUser';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace';

export const useDemoWorkspace = () => {
  const { currentWorkspaceId } = useWorkspace();
  const { workspaces } = useUser();
  const isDemo = useMemo(() => workspaces?.find((w) => w.id === currentWorkspaceId)?.name === 'Demo', [currentWorkspaceId, workspaces]);

  return isDemo;
};
