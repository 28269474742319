export const WORKSPACE_ID_KEY = 'current-workspace-id';

export const getStoredSelectedWorkspaceId = () => localStorage.getItem(WORKSPACE_ID_KEY);
export const storeSelectedWorkspaceId = (workspaceId: string | null) => {
  if (workspaceId) {
    localStorage.setItem(WORKSPACE_ID_KEY, workspaceId);
  } else {
    localStorage.removeItem(WORKSPACE_ID_KEY);
  }
};
