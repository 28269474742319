"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateWorkspaceObjectStageRequestDef = exports.CreateStageRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.CreateStageRequestDef = typebox_1.Type.Object({
    property_value: typebox_1.Type.String(),
    order: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
});
exports.CreateWorkspaceObjectStageRequestDef = typebox_1.Type.Object({
    object_property: typebox_1.Type.String(),
    display_name: typebox_1.Type.String(),
    show_in_record_creation_flow: typebox_1.Type.Boolean(),
    stages: typebox_1.Type.Array(exports.CreateStageRequestDef),
});
