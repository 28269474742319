"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateSlug = void 0;
const existsInReservedNames = (name, reservedNames) => reservedNames.map((name) => name.toLowerCase()).includes(name.toLowerCase());
const generateInitialSlug = (displayName) => {
    return displayName
        .replace(/[^a-zA-Z0-9\s.-]/g, '')
        .replace(/[\s.-]/g, '_')
        .toLowerCase();
};
const generateSlug = (name, reservedNames) => {
    let slug = generateInitialSlug(name);
    if (existsInReservedNames(slug, reservedNames)) {
        let counter = 1;
        let tempSlug = `${slug}-${counter}`;
        while (existsInReservedNames(tempSlug, reservedNames)) {
            counter++;
            tempSlug = `${slug}-${counter}`;
        }
        slug = tempSlug;
    }
    return slug;
};
exports.generateSlug = generateSlug;
