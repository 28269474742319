"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkHandleDomain = void 0;
var LinkHandleDomain;
(function (LinkHandleDomain) {
    LinkHandleDomain["FACEBOOK"] = "facebook.com";
    LinkHandleDomain["INSTAGRAM"] = "instagram.com";
    LinkHandleDomain["X"] = "x.com";
    LinkHandleDomain["TIKTOK"] = "tiktok.com";
    LinkHandleDomain["LINKEDIN"] = "linkedin.com";
    LinkHandleDomain["CRUNCHBASE"] = "crunchbase.com";
    LinkHandleDomain["PINTEREST"] = "pinterest.com";
    LinkHandleDomain["YOUTUBE"] = "youtube.com";
})(LinkHandleDomain || (exports.LinkHandleDomain = LinkHandleDomain = {}));
